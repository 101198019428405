/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useSolutionQuery } from '@querys/useSolutionQuery';
import { useSolutionSubcategoryQuery } from '@querys/useSolutionSubcategoryQuery';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { BackButton, ForwardButton } from '@components/UI/navButtons';
import cx from 'classnames';

const SolutionTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { modules, seo, slug } = page || {};
  const { solutions } = useSolutionQuery();
  const { subcats } = useSolutionSubcategoryQuery();

  const allItems = [...solutions, ...subcats];

  const [currentSolution, setCurrentSolution] = useState(0);
  const [previousSolution, setPreviousSolution] = useState(0);
  const [nextSolution, setNextSolution] = useState(0);

  useEffect(() => {
    const currentSolutionIndex = allItems.findIndex(
      solution => solution.slug.current === slug.current
    );
    setCurrentSolution(currentSolutionIndex);
    setPreviousSolution(currentSolutionIndex - 1);
    setNextSolution(currentSolutionIndex + 1);
  }, []);

  return (
    <Layout>
      <PageMeta {...seo} />
      {modules && (
        <ModuleZone {...modules} solutionType={page?.slug?.current} />
      )}
      <div
        className={cx('mt-space-m px-gutter w-full md:flex  blockH3 ', {
          'justify-end': currentSolution === 0,
          'justify-start': currentSolution === allItems.length - 1,
          'justify-between':
            currentSolution !== 0 && currentSolution !== allItems.length - 1,
        })}
      >
        {currentSolution !== 0 && (
          <BackButton
            linkText="Previous service"
            linkUrl={`/solutions/${allItems[previousSolution]?.slug?.current}`}
            extraClasses="justify-start  "
          />
        )}
        {nextSolution < allItems.length && (
          <ForwardButton
            linkText="Next service"
            linkUrl={`/solutions/${allItems[nextSolution]?.slug?.current}`}
            extraClasses="md:justify-end mt-5 md:mt-0"
          />
        )}
      </div>
    </Layout>
  );
};

export default SolutionTemplate;

export const pageQuery = graphql`
  query solutionQuery($slug: String!) {
    page: sanitySolution(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }

      modules {
        ...SolutionModules
      }

      title
      snippet
      icon {
        ...ImageWithPreview
      }
      slug {
        current
      }
    }
  }
`;
